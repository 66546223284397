const CalendarDateOptions = function () {
    return [
        {id: "manual", range: undefined},
        {
            id: "today", range: [new Date(new Date()),
                new Date(new Date().setHours(23, 59, 59))]
        },
        {
            id: "yesterday", range: [
                new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0)),
                new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(23, 59, 59))
            ]
        },
        {
            id: "this_week", range: [
                new Date(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1)).setHours(0, 0, 0, 0)),
                new Date(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 7)).setHours(23, 59, 59))
            ]
        },
        {
            id: "last_week", range: [
                new Date(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() - 6)).setHours(0, 0, 0, 0)),
                new Date(new Date(new Date().setDate(new Date().getDate() - new Date().getDay())).setHours(23, 59, 59))
            ]
        },
        {
            id: "this_month", range: [
                new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 0),
                new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59),
            ]
        },
        {
            id: "last_month", range: [
                new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1, 0, 0, 0, 0),
                new Date(new Date().getFullYear(), new Date().getMonth(), 0, 23, 59, 59),
            ]
        },
        {
            id: "this_year", range: [
                new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0),
                new Date(new Date().getFullYear(), 11, 31, 23, 59, 59),
            ]
        },
        {
            id: "last_year", range: [
                new Date(new Date().getFullYear() - 1, 0, 1, 0, 0, 0, 0),
                new Date(new Date().getFullYear() - 1, 11, 31, 23, 59, 59),
            ]
        }
    ]
}
/**
calculating years, months, and days for a specific day name between two dates for the calendar
 @param {Array} weekDaysDateRange The Daterange
 @param {Array} weekdays days name
* */
const getWeekDays = (weekDaysDateRange, weekdays) => {
    if (!weekDaysDateRange)
      return []
    else {
        let days = {Sunday: 0, Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6};
        let result = []
        weekdays.forEach((thisDay) => {
            let day = days[thisDay.key]
            let startDate = new Date(JSON.parse(JSON.stringify(weekDaysDateRange))[0])
            let endDate = new Date(JSON.parse(JSON.stringify(weekDaysDateRange))[1])
            // berchnet das startDate mit offset für thisDay
            startDate.setDate(startDate.getDate() + (day +7 -startDate.getDay()) % 7)
            while (startDate < endDate) {
                let result_date = {
                    'year': new Date(+startDate).getFullYear(),
                    'month': new Date(+startDate).getMonth(),
                    'day': new Date(+startDate).getDate()
                }
                // füget result_date hinzu falls es noch nicht vorhanden ist oder rechnet das neue startDate
                result.some(date_item => Object.keys(date_item).every(key => date_item[key] === result_date[key])) ?  startDate.setDate(startDate.getDate() + 7) :
                    result.push(result_date)

            }
        })
        return result
    }
}
export {CalendarDateOptions, getWeekDays}
